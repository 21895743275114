import React from 'react';
import {graphql, Link} from 'gatsby';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { withPrefix } from 'gatsby';

import Layout from 'src/components/Layout';
import TestImage1 from 'src/images/test-image-1.jpg';
import TestImage2 from 'src/images/test-image-2.jpg';
import TestImage3 from 'src/images/test-image-3.jpg';
import FarmProduct1 from 'src/images/farm-product-1.jpg';
import FarmProduct2 from 'src/images/farm-product-2.jpg';
import FarmImage from 'src/images/simplicity-farms.jpg';
import StripeImage1 from 'src/images/logo-stripe.png';
import GastbyIcon from 'src/images/gatsby-icon.png';
import TailwindIcon from 'src/images/tailwind.png';
import WarelmaLogo from 'src/images/warelma/logo.png';
import WarelmaSlide1 from 'src/images/warelma/94128065_2642535865857341_2579895264529612800_n.jpg';
import WarelmaSlide2 from 'src/images/warelma/94177071_2642535815857346_8114403470913568768_n.jpg';
import WarelmaSlide3 from 'src/images/warelma/149180634_3441109942666592_9123201495396084271_n.jpg';
import WarelmaSlide4 from 'src/images/warelma/deur.jpg';
import WarelmaSlide5 from 'src/images/warelma/117715383_2954087368035521_6207229410163580185_n.jpg';
import WarelmaSlide6 from 'src/images/warelma/117715383_2954087368035521_6207229410163580185_n.jpg';
import SimpleMap from 'src/components/SimpleMap';
import ContactBlock from "src/components/ContactBlock";


import SEO from 'src/components/SEO';
import ContactSection from "src/components/ContactSection";


const ReferencesPage = ({data}) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
    const references = data.allPrismicReferentie.nodes;
    console.log(references)
  return (
      <Layout>
        <SEO title="Referenties"/>
        <div className="max-w-6xl p-2 mt-4 sm:m-auto sm:p-12">
          <div className="container pt-2 pb-6 mx-auto mt-2 border-b-4 sm:block">
            <h1 className="py-4 text-center font-slim text-primary">Referenties</h1>
            <div className="flex flex-wrap justify-center px-1 md:px-6 my-4 md:flex-row sm:w-auto">
                {references.map((reference) => {

                    return (
                        <div className={"w-full md:w-1/2 px-4"}>
                            <div className="product  relative ">
                                <div className="relative">
                                    <div className="relative slides-warelma overflow-hidden">
                                        <Slider {...settings}>
                                            {reference.data.images.map(image => (
                                                <div>
                                                    <div
                                                        className="w-full slide-warelma"
                                                        style={{ backgroundImage: `url(${image.image.url})` }}
                                                    ></div>
                                                </div>
                                            ))}

                                        </Slider>

                                    </div>

                                </div>

                                <div className="px-6 py-4">
                                    <div className="mb-2 text-xl font-bold">
                                        <div dangerouslySetInnerHTML={{__html: reference.data.title.html}}></div>
                                    </div>
                                    <p className="mb-4 text-base text-gray-600">
                                        <div dangerouslySetInnerHTML={{__html: reference.data.reference_text.html}}></div>

                                    </p>
                                </div>
                            </div>
                        </div>
                    )

                })}



            </div>
          </div>
        </div>

        <ContactSection></ContactSection>

      </Layout>
  )


};

export default ReferencesPage;

export const pageQuery = graphql`
  query ReferencesQuery {
     allPrismicReferentie {
     nodes {
        data {
            images {
                image {
                    alt
                    copyright
                    url
                }
            }
            reference_text {
                html
            }
            title {
                html
            }
        }
    }
}
  }
`
